<template>
  <div>
    <big-title>
      Queremos <span>conocerte</span>.
    </big-title>

    <!-- SECCIONES -->

    <vx-card v-if="isMounted && !failed">
      <div slot="no-body" class="tabs-container px-6 pt-6">
        <vs-tabs v-model="activeTab" class="tab-action-btn-fill-container">
          <vs-tab label="Queremos conocerte" @click="$router.push({ name: 'kycSolicitante' })">
          </vs-tab>
          <vs-tab :label="group.title" icon-pack="feather" :icon="group.icon" v-for="(group, i) in sectionGroups" :key="i">
            <div class="tab-text">
              <vx-card v-for="section in group.sections" :key="section.data.id" :title="section.data.public_name" class="mb-5">
                <p class="mb-4" v-if="section.data.public_description != null">{{section.data.public_description}}</p>
                <div class="vx-row">
                  <template v-for="f in section.data.fields">
                    <!-- Campos automaticos -->
                    <form-field
                      :ref="group.slug"
                      v-if="isAutoFormField(f)"
                      :key="f.id"
                      :f="f"
                      :dataContainer="getContainerForField(section, f)"
                      :collectionsRoot="collections"
                      :onChangeReceptor="onFieldChange"
                      :evaluator="evaluateCondition" />
                  </template>
                </div>
              </vx-card>

              <!-- Save Button -->
              <div class="vx-row">
                <div class="vx-col w-full">
                  <div class="flex flex-wrap items-center justify-end">
                    <vs-button class="ml-auto mt-2" @click="saveTabChanges(group.slug)">Guardar cambios</vs-button>
                  </div>
                </div>
              </div>
            </div>
          </vs-tab>
          <vs-tab v-if="person_type_selected" label="Proveedor de recursos" @click="personTypeProvider()">
          </vs-tab>
        </vs-tabs>
      </div>
    </vx-card>
    <load-error v-if="failed" />
  </div>
</template>



<script>
import { mapState } from 'vuex';
import inputHelper from "@mixins/inputHelper";
import dateHelper from "@mixins/dateHelper";
import formHelper from "@mixins/formHelper";
import FormField from '@components/forms/FormField.vue'

const pfaeTabsDefs = [
  { slug: "seccion1", title: "Clasificación de persona",  icon: null, sections:[64] },
];

const sectionsToContainers = [
  { id: 64, containers: [{db:'pld_resource_providers', path:'user.pld_resource_provider'}] }
];

const requiredObjects = [
  'user.pld_resource_provider'
];

export default {
  mixins: [formHelper, inputHelper, dateHelper],
  data(){
    return {
      loading: false,
      isMounted: false,
      isRefreshing: false,
      errorMssg: '',
      successMssg: '',
      failed: false,
      allSections: [],
      sectionGroups: [],
      tabs: [],

      activeTab: 1,

      collections: {
        allPersonTypes: [],
      },

      // objeto principal
      base: {
        user: {
          personal: {},
          vulnerable_activities:[],
          pld_user_sofs:[],
          pld_user_pep:{},
          pld_initial_transactional_profile: {},
          pld_resource_provider: {
            personal: {},
            address: {
              country_id: 0
            }
          }
        }
      },
      // fin objeto principal

      // auxiliares para evaluacion de condiciones
      mexicoCountryId: 700,

      // objetos para actualizaciones
      basePayload: {},
      validatedFields: [],
    }
  },
  components: {
    FormField
  },
  computed: {
    ...mapState("auth", ["user"]),
    personal(){
      return this.base.user.personal;
    },
    resource_provider(){
      return this.base.user.pld_resource_provider;
    },
    person_type_selected(){
      if(this.resource_provider.person_type == 0 || this.resource_provider.person_type == 1 || this.resource_provider.person_type == 2 || this.resource_provider.person_type == 3){
        return true;
      }
    }
  },
  async mounted() {
    this.loading = true;
    this.isMounted = false;
    await this.getCollections();
    await this.getApplicantData();
    this.setPersonTypeInfo();
    await this.getFormInfo();
    this.setSectionGroups();
    this.isMounted = true;
    this.loading = false;
  },
  watch: {
    loading : function(newVal, oldVal){
      if(newVal == true){
        this.$vs.loading();
      }
      else {
        this.$vs.loading.close();
      }
    }
  },
  methods: {
    async reloadFormInfo(){
      this.isRefreshing = true;
      await this.getApplicantData();
      this.setPersonTypeInfo();
      this.reloadSectionGroups();
      this.isRefreshing = false;
    },
    async saveTabChanges(gslug){
      console.log("saving => " + gslug);
      let result = await this.runValidations(gslug);
      if(!result){
        this.missingFieldsNotif();
      }
      else {
        this.loading = true;

        //guardar solo la informacion que se ha modificado
        this.basePayload = this.collectFormData(gslug);
        this.basePayload.id = this.base.id;
        if(this.objectIsEmpty(this.basePayload)){
          //this.notFieldsToUpdate();
          this.saveSuccessNotif();
          this.loading = false;
          return;
        }

        try {
          // ejecutar guardado
          await axios.put(`/api/v1/applicant/${this.ApplicantId}/deepUpdateKyc`, this.basePayload);
          await this.reloadFormInfo();
          this.saveSuccessNotif();
        }
        catch (error) {
          this.failedOperationNotif(null, null);
        }

        this.loading = false;
      }
    },
    async runValidations(gslug){
      // validar componentes tipo FormField
      let res1 = await this.validateFormFields(gslug);
      if(!res1){
        return false;
      }

      // validar inputs instanciados en la vista
      let res2 = await this.$validator.validateAll();
      if(!res2){
        return false;
      }

      // inspeccionar los campos para ejecutar validaciones especiales en campos tipo FormField
      let spValidation = await this.checkFormFieldsSpecialValidations(gslug);
      if(!spValidation){
        return false;
      }

      // inspeccionar los campos agregados directo en el componente para ejecutar validaciones especiales
      let group = this.sectionGroups.find(g => g.slug == gslug);
      let sp_failed = false;
      group.sections.forEach(s => {
        s.data.fields.filter(f => f.special_definition == 1).forEach(f => {
          // considerar los campos habilitados (visibles), con validaciones especiales
          if(this.fields[f.fname] && f.special_validation != null && f.special_validation.method != null) {
            let res = this[f.special_validation.method]();

            if(!res){
              this.errors.add({
                field: f.fname,
                msg: f.special_validation.error
              });
              this.missingFieldsNotif(f.field_name, f.special_validation.error, 10000);
              sp_failed = true;
            }
          }
        });
      });

      if(sp_failed){
        return false;
      }

      // retornar true si todas las validaciones fueron positivas
      return true;
    },
    collectFormData(gslug){
      let payload = {};
      let group = this.sectionGroups.find(g => g.slug == gslug);
      group.sections.forEach(section => {
        section.containers.forEach(cc => {
          let fields = this.validatedFields.filter(vf => vf.db == cc.db);
          if(fields.length < 1){
            return;
          }

          if(cc.path == "base"){
            fields.forEach(f => {
              payload[f.fname] = cc.container[f.fname];
            });
          }
          else {
            let obj = null;
            obj = {id: cc.container.id};
            fields.forEach(f => {
              obj[f.fname] = cc.container[f.fname];
            });
            // ajuste para objetos tipo array
            this.setDeepObjectWithIds(payload, this.base, cc.path, obj);
          }
        });
      });
      return payload;
    },
    async validateFormFields(refGroup){
      let allValid = true;
      this.validatedFields = [];
      let grefs = this.$refs[refGroup];
      for(let p in grefs){
        let r = await this.$refs[refGroup][p].checkForValidDirty();
        if(r.valid == false){
          allValid = false;
        }
        else if(r.dirty == true) {
          this.validatedFields.push(r);
        }
      }
      return allValid;
    },
    async checkFormFieldsSpecialValidations(refGroup){
      let spvalid = true;
      let grefs = this.$refs[refGroup];
      for(let p in grefs){
        let sp = this.$refs[refGroup][p].specialValidation();
        if(sp != null){
          let res = this[sp.method]();
          let n = this.$refs[refGroup][p].fieldName;
          if(!res){
            spvalid = false;
            this.errors.add({
              field: n,
              msg: sp.error
            });
            this.missingFieldsNotif(n, sp.error, 10000);
          }
        }
      }

      return spvalid;
    },
    /* on change receptor */
    onFieldChange(method){
      if(method != null && method in this){
        console.log("onFieldChange => " + method)
        this[method]();
      }
    },
    /* evaluator */
    evaluateCondition(condition){
      if(condition != null && condition in this){
        let ss = this[condition];
        console.log("evaluateCondition => " + condition + ", " + ss);
        return ss;
      }
    },
    setSectionGroups(){
      this.tabs.forEach(t => {
        let group = { title: t.title, icon: t.icon, slug: t.slug };
        let sections = [];

        t.sections.forEach(ss => {
          let s = this.allSections.find(f => f.id == ss);
          if(s != null){
            let sb = { data: s, containers: this.getContainersForSection(s.id) };
            sections.push(sb);
          }
          else {
            this.warn("Section " + ss + " not found");
          }
        })
        group.sections = sections;
        this.sectionGroups.push(group);
      });
    },
    reloadSectionGroups(){
      this.sectionGroups.forEach(group => {
        group.sections.forEach(s => {
          s.containers = this.getContainersForSection(s.data.id);
        })
      });
    },
    getContainerForField(section, f){
      let c = section.containers.find(sc => sc.db == f.db_table);
      if(!c){
        this.warn(`Container not found for db [${f.fname}]: ${f.db_table}`);
        return null;
      }
      else {
        return c.container;
      }
    },
    getContainersForSection(section_id){
      let c = sectionsToContainers.find(f => f.id == section_id);
      if(!c){
        this.warn("Missing containers definition for section " + section_id);
        return null;
      }
      else {
        let sectionContainers = [];
        c.containers.forEach(cc => {
          let con = this.setContainerFromDef(cc);
          sectionContainers.push({db: cc.db, path: cc.path, container: con});
        })
        return sectionContainers;
      }
    },
    setContainerFromDef(c){
      let container = this.nestedFieldValue(this.base, c.path);
      if(Array.isArray(container)){
        let ac = null;
        if(c.arrayDefs){
          if(c.arrayDefs.getFirst == true){
            ac = container[0];
          }
          else if(c.arrayDefs.eval){
            // llamado en funciones de secciones
            ac = this[c.arrayDefs.eval](container);
          }
        }
        if(!ac){
          if(c.arrayDefs.onNull){ ac = this[c.arrayDefs.onNull](); }
          else { ac = {} }
          container.push(ac);
        }
        return ac;
      }
      else {
        return container;
      }
    },
    async getApplicantData(){
      try{
        // ?filter[status]=3 // ejemplo de filtro
        let params = "with[]=" + requiredObjects.join("&with[]=");
        let response = await axios.get(`/api/v1/applicant/${this.ApplicantId}?${params}`);
        this.base = response.data;
      }
      catch(e){
        this.warn(e);
        this.failed = true;
      }
    },
    async getFormInfo(){
      try{
        await this.getSectionInfo(64);
        this.allSections.forEach(s => {
          s.fields.forEach(f => {
            this.formFieldSetter(f, this, true);
          });
        });
      }
      catch(e){
        this.warn(e);
        this.failed = true;
      }
    },
    async getSectionInfo(id){
      try{
        let response = await axios.get("/api/v1/forms/getSectionFields/" + id);
        this.allSections.push(response.data.section);
      }
      catch(e){
        this.warn(e);
        this.failed = true;
      }
    },
    setPersonTypeInfo(){
      this.tabs = pfaeTabsDefs;
    },
    async getCollections(){
      try {
        let response5 = await axios.get('/api/v1/forms/getPersonTypes');
        this.collections.allPersonTypes = response5.data;
      } catch (e) {
        this.warn(e);
        this.failed = true;
      }
    },
    personTypeProvider(){
      this.$router.push({ name: 'kycSolicitanteProveedor' });
    }
  }
}
</script>